<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>

    <el-row class="content-box">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="场馆名">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="所属机构">
            <mechanism-select :mecid.sync="mechanism_id"></mechanism-select> 
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status">
            <el-option label="上架" :value="0"></el-option>
            <el-option label="冻结" :value="1"></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="类型">
          <el-select v-model="type">
            <el-option v-for="(type,i) in typeList" :label="type.label" :value="type.value" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="共享">
          <el-select v-model="sharing" claerable>
            <el-option v-for="(type,i) in shareList" :label="type.label" :value="type.value" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="flex">
            <el-button type="primary" @click="search()">查询</el-button>
          <el-button @click="openAdd" type="success" style="float: right"
            >新增</el-button
          >
          <excelExport ref="export" :tableName="tableName" :values="values" :titles="titles"
            @startExport="startExport"></excelExport>
          </div>
        </el-form-item>
       
      </el-form>

      <el-table :data="placeList" v-loading="isLoading">
        <el-table-column label="场馆标题" prop="name"> </el-table-column>
        <el-table-column label="场馆类目" prop="categories_child">
        </el-table-column>
        <el-table-column
          label="所属机构"
          prop="mechanismName"
        ></el-table-column>
        <el-table-column
          label="区域"
          prop="district"
        ></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0" style="color: green"
              >正常</span
            >
            <span v-if="scope.row.status == 1" style="color: red"
              >已冻结</span
            >
          </template>
        </el-table-column>
        <el-table-column label="类型" >
          <template slot-scope="{row}">
           <div>{{getTypeText(row)}}<span v-if="row.sharing != 'no'">~{{getLabel('shareList',row.sharing)}}</span> </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="" width="280">
          <template slot-scope="{row}">
            <div style="display: flex">
              <!-- <el-button size="small">下架</el-button> -->
              <el-button
                size="small"
                type="primary"
                @click="toPlaceDetails(row)"
                >查看详情</el-button
              >
              <el-button
                size="small"
                type="warning"
                @click="openEdit(row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="danger"
                v-if="row.status === 0"
                @click="frozen(row, 1)"
                >冻结
              </el-button>
              <el-button
                size="small"
                type="success"
                v-if="row.status === 1"
                @click="frozen(row, 0)"
                >解结
              </el-button>
                <el-button
                size="small"
                type="primary"
                @click="toAppointTemplate(row)"
                >设施排期
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="currentPageChange"
        :currentPage="currentPage"
        :total="placeTotal"
      ></paging-fy>
    </el-row>

    <!--  -->
    <addVenue
      ref="venue"
      @success="getPlaceList"
      :Data="curVenue"
      :mode="mode"
    ></addVenue>
  </div>
</template>

<script>
import searchAddr from "../../components/searchAddr.vue";
import addVenue from "./components/addVenue.vue";
import excelExport from "../../components/excel/excelExport.vue";

export default {
  components: { searchAddr, addVenue ,excelExport},
  data() {
    return {
      nav: {
        firstNav: "机构管理中心",
        secondNav: "场馆管理",
      },
      isLoading: true,
      // 场馆列表
      currentPage: 1,
      placeTotal: 0,
      placeList: [],
      mechanism_id: null,
      status: null,
      name: null,
      type:'venue',
   
      curVenue: {},
      mode: "add",
      // 表格
      DataList: [],
      tableName: "海马运动场馆",
      values: ["name",
        "categories_child",
        "address",
        "district",
        ],
      titles: ["场馆名称", "类目", "地址", "区域"],
      typeList: [
        {
          label: "机构场馆",
          value: "venue",
        },
         {
          label: "共享场地",
          value: "share_venue",
        }
      ],
       shareList: [
        {
          label: "不共享",
          value: "no",
        },
         {
          label: "共享",
          value: "share",
        },
         {
          label: "社区专属",
          value: "community",
        }
      ],
      sharing:''
    };
  },
 
  watch: {
    temp: {
      deep: true,
      handler(newV, oldV) {
        if (Object.keys(newV).length) {
          this.haveCate = newV.categories_child
            ? newV.categories_child.split("/")
            : [];
          this.form.type = newV.type;
          this.$set(this.form, "is_recommend", newV.is_recommend);
        }
      },
    },
  },
  methods: {
    toAppointTemplate(row){
        this.$router.push({
          name:'serviceAppointmentTemp',
          query:{vid:row.id}
        })
    },
    getTypeText(row){
        if(row.coach_id > 0){
          return '教练场地'
        }else if(row.type == 'venue'){
          return '机构场馆'
        }else if(row.type == 'share_venue'){
          return '共享场地'
        }
    },
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
    // 导出表格
    startExport() {
      this.exportInfo()
    },
    exportInfo() {
      this.$axios({
        url: "/user/mechanism/venue/query",
        params:{
          status: 0,
        }
      }).then(res => {
        this.DataList = res.data.data
        this.$refs.export.importExcel(this.DataList)
      })
    },
    openAdd() {
      this.curVenue = {};
      this.mode = "add";
      this.$refs.venue.open();
    },
    toCouDetails(row) {
      this.$router.push({
        name: "couDetails",
        query: {
          type: "mec",
          couID: row.id,
          couMec: row.map.masterMechanismEntity.mechanism_name,
        },
      });
    },
    search() {
      this.currentPage = 1;
      this.getPlaceList();
    },
    // 场馆列表分页获取
    getPlaceList() {
      this.isLoading = true;
      let url = "/user/mechanism/venue/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: this.status ? this.status : null,
            pageSize: 10,
            currentPage: this.currentPage,
            name: this.name ? this.name : null,
            type:this.type || null,
            sharing:this.sharing || null,
            mechanism_id: this.mechanism_id ? this.mechanism_id : null,
          },
        })
        .then((res) => {
          this.placeList = res.data.data.rows;
          this.placeTotal = res.data.data.total;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getPlaceList();
    },
   
    openEdit(row) {
      this.mode = "edit";
      this.curVenue = row;
      this.$refs.venue.open();
    },
  
    frozen(row, status) {
      let url = "user/mechanism/venue/freeze";
      this.$axios
        .post(url, {
          id: row.id,
          status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.getPlaceList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    pass(row, status) {
      let url = "user/mechanism/venue/update";
      this.$axios
        .post(url, {
          id: row.id,
          status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.getPlaceList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    // 场馆详情页
    toPlaceDetails(row) {
      this.$router.push({
        name: "venueDetails",
        params: {
          id:row.id
        },
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDialog = false;
          this.updateDialog = false;
          this.haveCate = [];
          this.form = {};
          done();
        })
        .catch((_) => {});
    },
  },
  mounted() {
    this.getPlaceList();
  },
};
</script>

<style>
.el-table thead tr th {
  background-color: #eaeaea !important;
}
</style>
