<template>
  <div>
    <el-popover
      confirm-button-text="好的"
      cancel-button-text="不用了"
      icon="el-icon-info"
      icon-color="red"
    >
      <div style="margin-bottom: 1rem">
        <span>详细地址</span>
        <el-input class="search_input" id="search_addr" v-model="search">
        </el-input>
      </div>
      <div id="confirm_addr" style="width: 40rem; height: 25rem"></div>
      <el-button slot="reference" style="max-width:25rem;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{ mechanism_addr || addr || '请选择地址' }}</el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    clear: {
      type: Boolean,
      default: false,
    },
    addr:{
      type:String
    }
  },
  data() {
    return {
      mechanism_addr: "",
      search: "",
    };
  },
  mounted(){
    console.log(this.addr);
  },
  methods: {
    drawMap() {
      let that = this;
      this.$map.then((AMap) => {
        let mapOption = {
          zoom: 14, //级别
          center: [116.404177, 39.909652],
          //中心点坐标
          viewMode: "3D", //使用3D视图,
          // scrollWheel: false,
        };
        let map = new AMap.Map("confirm_addr");
        AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
          var poiPicker = new PoiPicker({
            //city:'北京',
            input: "search_addr",
          });
          //初始化poiPicker
          poiPickerReady(poiPicker);
        });
        function poiPickerReady(poiPicker) {
          window.poiPicker = poiPicker;

          var marker = new AMap.Marker();

          var infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -20),
          });
          //选取了某个POI
          poiPicker.on("poiPicked", function (poiResult) {
            var source = poiResult.source,
              poi = poiResult.item,
              info = {
                source: source,
                id: poi.id,
                name: poi.name,
                location: poi.location.toString(),
                address: poi.address,
              };
            that.mechanism_addr = poi.address;
            that.$emit("transAddr", poi);
            marker.setMap(map);
            map.setZoom(13);
            infoWindow.setMap(map);
            marker.setPosition(poi.location);
            infoWindow.setPosition(poi.location);
            infoWindow.setContent(info.name + "<hr />" + info.address);
            infoWindow.open(map, marker.getPosition());

            map.setCenter(marker.getPosition());
          });

          poiPicker.onCityReady(function () {
            poiPicker.suggest("美食");
          });
        }
        // AMap.plugin(["AMap.Autocomplete", "AMap.PlaceSearch"], function () {
        //   let autoOptions = {
        //     // 城市，默认全国
        //     city: "全国",
        //     // 使用联想输入的input的id
        //     input: "search_addr",
        //     output: "map_result",
        //   };
        //   let autocomplete = new AMap.Autocomplete(autoOptions);

        //   let placeSearch = new AMap.PlaceSearch({
        //     city: "全国",
        //     map: map,
        //   });
        //   AMap.event.addListener(autocomplete, "select", function (e) {
        //     console.log(e.poi);
        //     that.$emit("transAddr", e.poi);
        //     //TODO 针对选中的poi实现自己的功能
        //     placeSearch.search(e.poi.name);
        //   });
        // });
      });
    },
  },
  created() {
    this.drawMap();
  },
  watch: {
    // mechanism_addr(newV, oldV) {
    //   if (newV != null) {
    //     this.$emit("transAddr", newV);
    //   }
    // },
    clear(newV, oldV) {
      if (newV) {
        this.mechanism_addr = "";
        this.search = "";
      }
    },
  },
};
</script>

<style>
#map_result {
  position: absolute;
  z-index: 2020;
  background-color: white;
}
.search_input {
  width: 28rem !important;
}
</style>