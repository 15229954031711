<template>
  <div>
    <el-dialog :visible.sync="addServiceDialog" width="80%" title="场馆">
      <el-form
        :model="form"
        label-width="auto"
        :rules="rules"
        ref="courseForm"
        style="display: flex; justify-content: space-around; flex-wrap: wrap"
      >
        <div>
          <el-form-item label="场馆类型" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio label="venue">机构场馆</el-radio>
              <el-radio label="share_venue">共享场地</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所属机构">
            <el-select
              v-model="form.mechanismName"
              placeholder="输入机构名称或手机号"
              filterable
              clearable
              remote
              :remote-method="searchOrgName"
            >
              <el-option
                v-for="(item, index) in mechanism_name_list"
                :key="index"
                :label="item.mechanism_name"
                :value="item.id"
                @click.native="changeMec(item)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场馆名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="类目">
            <el-select
              v-model="form.categories_child"
              :placeholder="form.categories_child || '场馆类目'"
              clearable
            >
              <el-option
                v-for="(item, index) in categories_child_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="省市区" required>
            <city-select @change="changeCity" :temp="cityList"></city-select>
          </el-form-item>
          <el-form-item label="详细地址" required>
            <el-input v-model="form.address">
              <addr-select
                slot="append"
                @getform="addMecAddr"
                type="icon"
              ></addr-select>
            </el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input v-model="form.longitude" placeholder="经度"></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input v-model="form.latitude" placeholder="纬度"></el-input>
          </el-form-item>
          <el-form-item label="营业时间">
            <el-input
              v-model="form.banking_hours"
              placeholder="如：周一至周日08:00-22:00"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="是否优选">
            <el-radio-group v-model="form.is_recommend">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">优选</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="场地共享">
            <el-radio-group v-model="form.sharing">
              <el-radio label="no">不共享</el-radio>
              <el-radio label="share">共享</el-radio>
              <el-radio label="community">社区专属</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="系统服务费" v-if="form.sharing == 'share'" v-permission="'setShareSysServiceRatio'">
             <el-input
                v-model="form.sys_service_ratio"
                placeholder="系统服务费"
              ></el-input>
          </el-form-item>
          <el-form-item label="开发教练" v-if="form.sharing == 'share'">
            <coach-select ref="devCoach" @change="changeCoach"></coach-select>
          </el-form-item>
          <el-form-item label="封面" ref="imgUpRef" required>
            <avatar-uploader
              @getImgUrl="getCover"
              :url="form.images"
              :clear="if_clear"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="详情图片">
            <images-uploader
              @getImgUrl="getProductImg"
              :urls="form.introduction_pic"
              :clear="if_clear"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="简介">
            <el-input
              type="textarea"
              v-model="form.introduction_content"
              placeholder="简介"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addServiceDialog = false">取 消</el-button>
        <el-button v-if="mode == 'add'" type="primary" @click="submitForm()"
          >添 加</el-button
        >
        <el-button v-else type="primary" @click="updateForm()">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { rules } from "./rules_v";
import { ObjectChangedValue } from "@/js/utils";
import CoachSelect from "../../../components/select/coachSelect.vue";
export default {
  components: {
    CoachSelect,
  },
  data() {
    return {
      form: {},

      temp: {},
      rules: rules,
      addServiceDialog: false,
      select_mechanism: "",
      mechanism_name_list: [],
      venues_list: "",
      if_clear: true,
      count: 1,
      cityList: [],
      dev_coach: "",
    };
  },
  props: {
    Data: {
      type: Object,
    },
    mode: {
      type: String,
      default: "add",
    },
  },
  watch: {
    Data: {
      immediate: true,
      handler: function (val, oldVal) {
        if (this.mode == "edit") {
          this.editService();
        }
      },
    },
    mode: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        this.if_clear = true;
        if (val == "add") {
          this.form = { sharing: "no" };
          this.if_clear = false;
          this.cityList = [];
          this.temp = {};
        }
      },
    },
  },
  computed: {
    categories_child_list() {
      return this.$store.state.categories_child_list;
    },
  },
  mounted() {},

  methods: {
    changeCoach(v) {
      this.form.dev_coach = v.id;
    },
    // 新增机构地址
    addMecAddr(addr) {
      if (addr.address) {
        this.$set(this.form, "province", addr.province);
        this.$set(this.form, "city", addr.city);
        this.$set(this.form, "district", addr.district);
        this.$set(this.form, "address", addr.address);
        this.cityList = [];
        this.cityList.push(addr.province);
        this.cityList.push(addr.city);
        this.cityList.push(addr.district);
      }

      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
    },
    changeCity(v) {
      this.cityList = v;
      this.form.province = v[0];
      this.form.city = v[1];
      this.form.district = v[2];
    },
    changeMec(e) {
      this.form.mechanismName = e.mechanism_name;
      this.cityList = [];
      this.cityList.push(e.province);
      this.cityList.push(e.city);
      this.cityList.push(e.district);
      this.$set(this.form, "province", e.province);
      this.$set(this.form, "city", e.city);
      this.$set(this.form, "district", e.district);
      this.$set(this.form, "latitude", e.latitude);
      this.$set(this.form, "longitude", e.longitude);
      this.$set(this.form, "address", e.mechanism_addr);
      this.$set(this.form, "mechanism_id", e.id);
    },
    editService() {
      this.cityList = [];
      this.form = this.Data;
      this.cityList.push(this.Data.province);
      this.cityList.push(this.Data.city);
      this.cityList.push(this.Data.district);
      this.temp.data = { ...this.form };
    },
    open() {
      this.addServiceDialog = true;
      this.$nextTick(() => {
        this.$refs.devCoach.clear();
      });
    },
    updateForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.updateServie();
        }
      });
    },
    submitForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.addNew();
        }
      });
    },
    addNew() {
      let url = "/user/mechanism/venue/insert";
      this.if_clear = false;
      console.log(this.form);

      this.$axios.post(url, this.form).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "添加成功!" });
          this.addServiceDialog = false;
          this.if_clear = true;
          this.form = {};
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    updateServie() {
      let data = ObjectChangedValue(this.temp.data, this.form);
      data.id = this.form.id;
      this.if_clear = false;
      let url = "/user/mechanism/venue/update";
      this.form.deduction_course_num = 1;
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: "修改成功!" });
          this.addServiceDialog = false;
          this.if_clear = true;
          this.form = {};
          this.temp = {};
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    // 机构查询远程查询
    searchOrgName(query) {
      if (query && typeof query == "string") {
        let url = "/user/mastermechanism/queryByMessage";
        let data = {
          type: "teach_paypal",
          pageSize: 30,
          currentPage: 1,
          status: 2,
        };
        const numberReg = /^\d/;
        numberReg.test(query)
          ? (data.mechanism_telephone = query)
          : (data.mechanism_name = query);
        this.$axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            this.mechanism_name_list = res.data.data.rows;
          })
          .catch((err) => {});
      }
    },
    getVenuesList(query) {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: "场馆",
            categories: "体育运动",
            source: "体育宝",
            binding_venues_id: 0,
            title: typeof query == "string" && query != "" ? query : null,
            mechanism_id: this.form.mechanism_id
              ? this.form.mechanism_id
              : null,
          },
        })
        .then((res) => {
          this.venues_list = res.data.data.rows;
        })
        .catch((err) => {});
    },
    getCover(url) {
      this.form.images = url;
    },
    getProductImg(url) {
      this.form.introduction_pic = url;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>